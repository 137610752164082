<script lang="ts" setup>
import { computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { usePrimeSurveysIntegration } from '@/utils/composables/useIntegrationScriptRender';
import { ACTIVITIES, HELP } from '@/locales/constants';
import { PrimeLoaderSpinner } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import type { IntegrationModules } from '@/types';

const route = useRoute();
const i18n = useI18n();
const { renderPrimeSurveys, integrationScriptLoading } =
	usePrimeSurveysIntegration();

const pageTitle = computed(() => {
	const titles: Record<string, string> = {
		'recent-activity': ACTIVITIES,
		'recent-activity-offers': ACTIVITIES,
		support: HELP,
	};
	return titles[String(route.name)] || '';
});

onMounted(() =>
	renderPrimeSurveys(
		'#prime-integrated-page',
		route.name as IntegrationModules,
		i18n.t(pageTitle.value)
	)
);
</script>

<template>
	<main class="integrated-page">
		<PrimeLoaderSpinner v-if="integrationScriptLoading" />
		<div id="prime-integrated-page" class="integrated-container" />
	</main>
</template>

<style scoped lang="scss">
@import '@/styles/mixins.scss';

.integrated-page {
	width: 100%;

	@include breakpoint(mobile) {
		margin-top: 2.25rem;
	}

	:deep(header.common-header) {
		border: none;
		padding: 0.625rem 0;

		& .p-text {
			font-size: 1rem;
		}
	}

	:deep(.common-header-button button) {
		padding: 0.5rem;

		@include breakpoint(mobile) {
			padding: 0.65rem;
		}
	}

	:deep(.common-header),
	:deep(.common-title),
	:deep(.common-container),
	:deep(.common-content.background),
	:deep(.common-content.background .common-content-fixed) {
		background: none;
	}
	:deep(.common-content.recent-activity__page-content .common-container) {
		min-height: 20px;
	}

	:deep(.common-content-main),
	:deep(.common-header-nav) {
		padding: 0;
	}

	:deep(.p-loader-spinner) {
		height: 100%;
	}

	:deep(.subpage) {
		overflow: hidden;
		padding: 0;
	}
}

.integrated-container {
	height: 100%;
	display: flex;
	flex-direction: column;
}
</style>
