<script lang="ts" setup>
import type { Modal } from '@/types';
import type { Component } from 'vue';
import { watch, ref, onMounted, onBeforeUnmount } from 'vue';
import { useModalStorage } from '@/utils/composables/useModalStorage';
import { useDocumentVisibility } from '@vueuse/core';
import { useMessagesStore } from '@/stores/messages';
import {
	DELETE_ACCOUNT_MODAL,
	USER_LEVEL_MODAL,
	USER_STREAK_MODAL,
	CHANGE_EMAIL_MODAL,
	CHANGE_PASSWORD_MODAL,
	CHANGE_USERNAME_MODAL,
	CHANGE_TIMEZONE_MODAL,
	WELCOME_BONUS_MODAL,
	PRESET_WELCOME_BONUS_MODAL,
	REWARDS_MODAL,
	REWARD_SENT_MODAL,
	LEADERBOARD_MODAL,
	PRIZE_DRAW_MODAL,
	NEW_PASSWORD_SENT,
	ACHIEVEMENT_UNLOCKED_MODAL,
	CHANGE_LANGUAGE_MODAL,
	ONE_CLICK_SURVEY_MODAL,
	SELECT_COUNTRY_LANGUAGE_MODAL,
	SET_USER_PASSWORD_MODAL,
	NEW_LEVELS_MODAL,
	CONFIRM_CLAIM_DATA_MODAL,
	BONUS_CODE_MODAL,
	APP_REVIEW_MODAL,
	CONFIRM_CLAIM_MODAL,
	GOOGLE_TRANSLATE_MODAL,
	BONUS_DAY_MODAL,
	PRIZE_BONUS_MODAL,
	ASK_TRACKING_PERMISSIONS_MODAL,
	OFFERWALLS_ONETIME_NOTIFICATION_MODAL,
	OFFERWALLS_REWARD_NOTIFICATION_MODAL,
	DYNAMIC_ANNOUNCEMENT_MODAL,
	COPY_REFERRAL_LINK_MODAL,
	UPDATE_AVAILABLE_MODAL,
	OFFERWALLS_WELL_DONE_MODAL,
	OFFERWALLS_PENDING_MODAL,
	ANNOUNCEMENT_MODAL_VERSION,
	CASHBACK_ANNOUNCEMENT_MODAL,
	CASHBACK_RECEIVED_NOTIFICATION_MODAL,
} from '@/constants/modals';
import UpdateAvailableModal from '@/components/modals/UpdateAvailableModal.vue';
import ModalWrapper from '@/components/common/ModalWrapper.vue';
import DeleteAccountModal from '@/components/modals/DeleteAccountModal.vue';
import UserCongratsLevelModal from '@/components/modals/CongratsLevelModal.vue';
import UserCongratsStreakModal from '@/components/modals/CongratsStreakModal.vue';
import ChangeEmailModal from '@/components/modals/ChangeEmailModal.vue';
import ChangePasswordModal from '@/components/modals/ChangePasswordModal.vue';
import ChangeUsernameModal from '@/components/modals/ChangeUsernameModal.vue';
import ChangeTimezoneModal from '@/components/modals/ChangeTimezoneModal.vue';
import PresetWelcomeBonusModal from '@/components/modals/PresetWelcomeBonusModal.vue';
import RewardsModal from '@/components/modals/RewardsModal.vue';
import RewardSentModal from '@/components/modals/RewardSentModal.vue';
import LeaderboardModal from '@/components/modals/LeaderboardModal.vue';
import PrizeDrawModal from '@/components/modals/PrizeDrawModal.vue';
import NewPasswordSentModal from '@/components/modals/NewPasswordSentModal.vue';
import AchievementUnlockedModal from '@/components/modals/AchievementUnlockedModal.vue';
import OneClickSurveyModal from '@/components/modals/OneClickSurveyModal.vue';
import ChangeLanguageModal from '@/components/modals/ChangeLanguageModal.vue';
import SelectCountryLanguageModal from '@/components/modals/SelectCountryLanguageModal.vue';
import SetUserPasswordModal from '@/components/modals/SetUserPasswordModal.vue';
import NewLevelsModal from '@/components/modals/NewLevelsModal.vue';
import ConfirmPaypalEmailModal from '@/components/modals/ConfirmClaimDataModal.vue';
import BonusCodeModal from '@/components/modals/BonusCodeModal.vue';
import AppReviewModal from '@/components/modals/AppReviewModal.vue';
import ConfirmClaimModal from '@/components/modals/ConfirmClaimModal.vue';
import GoogleTranslateModal from '@/components/modals/GoogleTranslateModal.vue';
import BonusDayModal from '@/components/modals/BonusDayModal.vue';
import PrizeBonusModal from '@/components/modals/PrizeBonusModal.vue';
import { MessageChannels } from '@/enums';
import AskTrackingPermissionsModal from '@/components/modals/AskTrackingPermissionsModal.vue';
import OfferwallsOnetimeNotificationModal from '@/components/modals/notifications/OfferwallsOnetimeNotificationModal.vue';
import OfferwallRewardNotificationModal from '@/components/modals/notifications/OfferwallRewardNotificationModal.vue';
import DynamicAnnouncementModal from '@/components/modals/announcements/DynamicAnnouncementModal.vue';
import CopyReferralLinkModal from '@/components/modals/CopyReferralLinkModal.vue';
import OfferwallsWellDoneModal from '@/components/modals/OfferwallsWellDoneModal.vue';
import OfferwallsPendingModal from '@/components/modals/OfferwallsPendingModal.vue';
import AnnouncementModalVersion from '@/components/modals/AnnouncementModalVersion.vue';
import CashbackAnnouncementModal from '@/components/modals/announcements/CashbackAnnouncementModal.vue';
import CashbackRecievedNotificationModal from '@/components/modals/notifications/CashbackRecievedNotificationModal.vue';
import API from '@/api';

const MODAL_MAP: Record<string, Component> = {
	[DELETE_ACCOUNT_MODAL]: DeleteAccountModal,
	[USER_LEVEL_MODAL]: UserCongratsLevelModal,
	[USER_STREAK_MODAL]: UserCongratsStreakModal,
	[CHANGE_EMAIL_MODAL]: ChangeEmailModal,
	[CHANGE_PASSWORD_MODAL]: ChangePasswordModal,
	[CHANGE_USERNAME_MODAL]: ChangeUsernameModal,
	[CHANGE_TIMEZONE_MODAL]: ChangeTimezoneModal,
	[PRESET_WELCOME_BONUS_MODAL]: PresetWelcomeBonusModal,
	[REWARDS_MODAL]: RewardsModal,
	[REWARD_SENT_MODAL]: RewardSentModal,
	[LEADERBOARD_MODAL]: LeaderboardModal,
	[PRIZE_DRAW_MODAL]: PrizeDrawModal,
	[NEW_PASSWORD_SENT]: NewPasswordSentModal,
	[ACHIEVEMENT_UNLOCKED_MODAL]: AchievementUnlockedModal,
	[CHANGE_LANGUAGE_MODAL]: ChangeLanguageModal,
	[ONE_CLICK_SURVEY_MODAL]: OneClickSurveyModal,
	[SELECT_COUNTRY_LANGUAGE_MODAL]: SelectCountryLanguageModal,
	[SET_USER_PASSWORD_MODAL]: SetUserPasswordModal,
	[NEW_LEVELS_MODAL]: NewLevelsModal,
	[CONFIRM_CLAIM_DATA_MODAL]: ConfirmPaypalEmailModal,
	[BONUS_CODE_MODAL]: BonusCodeModal,
	[APP_REVIEW_MODAL]: AppReviewModal,
	[CONFIRM_CLAIM_MODAL]: ConfirmClaimModal,
	[GOOGLE_TRANSLATE_MODAL]: GoogleTranslateModal,
	[BONUS_DAY_MODAL]: BonusDayModal,
	[PRIZE_BONUS_MODAL]: PrizeBonusModal,
	[ASK_TRACKING_PERMISSIONS_MODAL]: AskTrackingPermissionsModal,
	[OFFERWALLS_ONETIME_NOTIFICATION_MODAL]: OfferwallsOnetimeNotificationModal,
	[OFFERWALLS_REWARD_NOTIFICATION_MODAL]: OfferwallRewardNotificationModal,
	[DYNAMIC_ANNOUNCEMENT_MODAL]: DynamicAnnouncementModal,
	[COPY_REFERRAL_LINK_MODAL]: CopyReferralLinkModal,
	[UPDATE_AVAILABLE_MODAL]: UpdateAvailableModal,
	[OFFERWALLS_WELL_DONE_MODAL]: OfferwallsWellDoneModal,
	[OFFERWALLS_PENDING_MODAL]: OfferwallsPendingModal,
	[ANNOUNCEMENT_MODAL_VERSION]: AnnouncementModalVersion,
	[CASHBACK_ANNOUNCEMENT_MODAL]: CashbackAnnouncementModal,
	[CASHBACK_RECEIVED_NOTIFICATION_MODAL]: CashbackRecievedNotificationModal,
};

const messageStore = useMessagesStore();
const visibility = useDocumentVisibility();
const { shiftModal, storage, activeModal } = useModalStorage();
const modal = ref<Modal | undefined>(undefined);
const showModal = ref(false);
const timeout = ref<any>(null);

const handleCloseModal = async () => {
	if (visibility.value === 'visible') {
		try {
			if (modal.value?.options?.id && !modal.value?.options?.achievement_key) {
				switch (modal.value?.options?.channel) {
					case MessageChannels.NOTIFICATION:
						await messageStore.readNotification(modal.value.options.id);
						break;
					case MessageChannels.ANNOUNCEMENT:
						await messageStore.readAnnouncement(modal.value.options.id);
						break;
					default:
						break;
				}
			}
		} catch (error) {
			console.error(error);
			await API.post('errors/js', {
				message: `
				:::: MODAL CLOSE ERROR :::: ${modal.value?.name}
				:::: MODAL OPTIONS :::: ${JSON.stringify(modal.value?.options)}
				:::: MODAL DATA :::: ${JSON.stringify(modal.value?.data)}
				:::: ERROR message :::: ${error?.message}`,
			});
		} finally {
			await shiftModal();
		}
	}
};

onMounted(async () => {
	if (
		visibility.value === 'visible' &&
		!Object.keys(activeModal.value).length &&
		storage.value.length
	) {
		await shiftModal();
	}
});

watch(
	() => storage.value,
	async (value) => {
		if (visibility.value === 'visible' && value.length && !modal.value) {
			await shiftModal();
		}
	}
);

watch(visibility, async (value) => {
	if (value === 'visible' && !modal.value && storage.value.length) {
		await shiftModal();
	}
});

watch(
	activeModal,
	(value) => {
		if (Object.keys(value).length) {
			modal.value = { ...value };
			showModal.value = Boolean(modal.value);
		} else {
			showModal.value = false;

			if (modal.value?.options?.position === 'bottom') {
				// Set timeout for transition duration on close
				timeout.value = setTimeout(() => {
					modal.value = undefined;
					clearTimeout(timeout.value);
				}, 550);
			} else {
				modal.value = undefined;
			}
		}
	},
	{ immediate: true }
);

onBeforeUnmount(() => {
	clearTimeout(timeout.value);
});
</script>

<template>
	<ModalWrapper
		:show="showModal"
		:use-default-close="modal?.options?.useDefaultClose ?? true"
		:position="modal?.options?.position"
		:animate="modal?.options?.animate"
		:is-scrolable="modal?.options?.isScrolable"
		:data-test-id="modal?.name || ''"
		:channel="modal?.options?.channel"
		@close-modal="handleCloseModal"
	>
		<component
			:is="{ ...MODAL_MAP[modal.name] }"
			v-if="modal?.name"
			:open="showModal"
			:options="modal.options"
			:data="modal.data"
			@close-modal="handleCloseModal"
		/>
	</ModalWrapper>
</template>
