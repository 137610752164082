import { useUserStore } from '@/stores/user';
import { storeToRefs } from 'pinia';
import { computed, watch, watchEffect } from 'vue';
import type { ComputedRef } from 'vue';
import { useRoute } from 'vue-router';
import { useDev } from '@/utils/composables/useDev';
import { showPrimeChat } from '@/utils/local-storage';
import { useAuthStore } from '@/stores/auth';

export const useLiveChat = (): {
	isPrimeChatEnabled: ComputedRef<boolean>;
} => {
	const route = useRoute();
	const userStore = useUserStore();
	const { userId, isPrimeChatAvailableForUser, isSupportChatAvailable } =
		storeToRefs(userStore);
	const { adminId, isAuthorized, isSuperAdmin } = storeToRefs(useAuthStore());
	const { isDev } = useDev();

	const isPrimeChatEnabled = computed(() => {
		if (!isAuthorized.value) return false; // User is not authorized
		if (!isSupportChatAvailable.value) return false; // Any chat is disabled

		if (!userId.value || (adminId.value && !isSuperAdmin.value)) return false;
		if (import.meta.env.VITE_PC_SCOPE === 'dev') return isDev.value;
		return isPrimeChatAvailableForUser.value || isDev.value;
	});

	const shouldRenderIntercom = computed(() => {
		if (!isAuthorized.value) return false; // User is not authorized
		if (!isSupportChatAvailable.value) return false; // Any chat is disabled
		if (adminId.value && !isSuperAdmin.value) return false; // Disable for admin

		return !!(
			userId.value &&
			!isPrimeChatEnabled.value &&
			window.Intercom !== undefined
		);
	});

	watchEffect(() => {
		showPrimeChat.value = !shouldRenderIntercom.value;
		if (shouldRenderIntercom.value) {
			window?.Intercom('boot', {
				api_base: 'https://api-iam.intercom.io',
				app_id: import.meta.env.VITE_INTERCOM,
				user_id: userId.value,
			});
		}
	});

	// Fetch intercom chat messages on each route change
	watch(
		() => route.name,
		() => {
			if (shouldRenderIntercom.value) {
				window.Intercom('update');
			}
		}
	);

	return {
		isPrimeChatEnabled,
	};
};
